import React from 'react';
import { navigate, Link } from 'gatsby';

import http from '../../services/http.service';
import StorageService from '../../services/storage.service';
import Layout from '../../components/Layout/Layout';
import { useDisplayServerError } from '../../hooks/useDisplayServerError';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import { API, HTTP_STATUS } from '../../constans/http';
import {
  BLOCKED_USER_LINK,
  VERIFICATION_LINK,
  MY_PROPERTIES_LINK,
  SEARCH_LINK,
} from '../../constans/links';
import Roles from '../../constans/roles';
import SignInForm from './form';

import classes from './index.module.scss';

function SignIn() {
  const [serverError, setServerError] = useDisplayServerError();

  const formSubmitHandler = (values) => {
    http
      .post(API.USER_LOGIN, { ...values })
      .then(({ data: { session, user } }) => {
        const client = user;
        const { searchProfile } = client;

        if (searchProfile) {
          const {
            // eslint-disable-next-line no-unused-vars
            id, createdAt, updatedAt, ...restOfProfile
          } = searchProfile;

          client.searchProfile = restOfProfile;
        }

        StorageService().set(LOCALSTORAGE_KEYS.SESSION, session);
        StorageService().set(LOCALSTORAGE_KEYS.USER, client);

        // eslint-disable-next-line max-len
        if (user?.role === Roles.SUPPLIER && user?.role !== Roles.PARTNER && user?.role !== Roles.RETAIL_CUSTOMER) {
          navigate(MY_PROPERTIES_LINK);
          // eslint-disable-next-line max-len
        } else if (user?.role === Roles.RETAIL_CUSTOMER && user?.role !== Roles.PARTNER && user?.role !== Roles.SUPPLIER) {
          if (user?.searchProfile) {
            navigate(SEARCH_LINK);
          } else {
            navigate('/customers/complete-profile/');
          }
        } else if (user?.role === Roles.PARTNER) {
          navigate(`/?BP=${user?.nickName}`);
        } else navigate(SEARCH_LINK);
      })
      .catch(({ status, message }) => {
        if ([HTTP_STATUS.BLOCKED_USER].includes(status)) {
          navigate(BLOCKED_USER_LINK);
        } else if ([HTTP_STATUS.UNVERIFIED].includes(status)) {
          navigate(VERIFICATION_LINK, { state: { pwdRedirect: true } });
        } else {
          setServerError(message);
        }
      });
  };

  return (
    <div className="footer-hidden column-flexed-wrapper">
      <Layout>
        <div
          className={`${classes.section} blue-grad-bg flex-grow-1 flex flex-h-start`}
        >
          <div className="auth-wrapper container-bordered">
            <div className="auth-title block-title">
              Sign In
              {serverError && <div className="titleError">{serverError}</div>}
            </div>
            <SignInForm formSubmitHandler={formSubmitHandler} />
            <div className={`${classes.signInWrapper} t-600`}>
              <span>Don&apos;t have an account?</span>
              {' '}
              <Link to="/signup/">Sign Up</Link>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default SignIn;
